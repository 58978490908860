// api.js
export const fetchBreakingNews = async (url) => {
    try {
        const response = await fetch(`https://portofolio.jii4you.my.id/api${url}`);
        if (!response.ok) {
            throw new Error("Failed to fetch breaking news");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error("Failed to fetch breaking news: " + error.message);
    }
};
