import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { fetchBreakingNews } from '../../api/FetchData';

const HomePages = () => {

    const [profile, setProfile] = useState([]);
    const [about, setAbout] = useState([]);
    const [project, setProject] = useState([]);
    const [education, setEducation] = useState([]);
    const [experience, setExperience] = useState([]);
    const [footer, setFooter] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchBreakingNews("/portofolio");

                setProfile(response.data.profile);
                setAbout(response.data.about);
                setProject(response.data.project);
                setEducation(response.data.education);
                setExperience(response.data.experience);
                setFooter(response.data.footer);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const [activeTab, setActiveTab] = useState('profile'); // Atur tab default

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <>
            <div className="mx-auto lg:w-4/5 w-full px-3 lg:px-8">
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mx-0 lg:flex lg:max-w-3xl">
                    <div className="-mt-2 p-2 lg:mt-0 lg:max-w-xs lg:flex-shrink-0">
                        <div className="mx-auto max-w-xs w-72 h-72">
                            <img src={`https://portofolio.jii4you.my.id/storage/${profile && profile[0] && profile[0].image}`} className="w-full h-full rounded-full object-cover" alt=""/>
                        </div>
                    </div>
                    <div className="p-4 sm:p-10 lg:flex-auto">
                        <div className='flex items-center lg:justify-normal justify-center'>
                            <p className="font-light text-base leading-7 text-gray-200">
                                {profile && profile[0] && profile[0].profesion}
                            </p>
                        </div>
                        <div className='flex item-center lg:justify-normal justify-center gap-2 mt-3'>
                            <h3 className="text-2xl font-bold tracking-tight text-blue-700">
                                {profile && profile[0] && profile[0].name}
                            </h3>
                            <span className='text-green-500'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
                                </svg>
                            </span>
                        </div>
                        <p className="mt-3 text-sm text-justify leading-5 text-gray-200">
                            {profile && profile[0] && profile[0].description}
                        </p>
                        <div className="flex lg:justify-start lg:gap-2 justify-between">
                            <p
                            className="mt-3 text-xs font-bold border border-gray-700 hover:bg-blue-500 hover:text-white px-2 rounded-md leading-7 text-gray-200"
                            >
                            {profile && profile[0] && profile[0].email}
                            </p>
                            <p
                            className="mt-3 text-xs font-bold border border-gray-700 hover:bg-blue-500 hover:text-white px-2 rounded-md leading-7 text-gray-200"
                            >
                            {profile && profile[0] && profile[0].phone}
                            </p>
                        </div>
                    </div> 
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 my-6">
                    <div className="lg:max-w-sm w-full h-70 relative group overflow-hidden lg:px-6 px-4 py-5 rounded-xl border border-gray-700 dark:border-gray-800 dark:bg-gray-900">
                    <div aria-hidden="true" className="inset-0 absolute aspect-video border border-gray-700 -translate-y-1/2 group-hover:-translate-y-1/4 duration-300 bg-gradient-to-b from-blue-900 to-slate-300 dark:from-white dark:to-white blur-2xl opacity-10 dark:opacity-5 dark:group-hover:opacity-10"></div>
                        <div className="relative">
                            <div className="flex border-b border-gray-700">
                                <p className="text-lg font-semibold text-gray-200 mb-4">About</p>
                            </div>

                            <div className="border-b border-gray-700 rounded-b-[--card-border-radius]">
                                <p className="text-gray-200 dark:text-gray-300 my-2">
                                    {about && about[0] && about[0].description}
                                </p>
                            </div>
                            <div className="flex gap-3 -mb-5 py-4">
                                <div className="group flex gap-2 items-center text-base h-8 justify-center">
                                    <i className="ri-map-pin-2-line text-gray-200"></i>
                                    <span className="hover:text-blue-500 text-gray-200">{about && about[0] && about[0].location}</span>
                                </div>
                            </div>
                            <div className="flex gap-3 -mb-5 py-4">
                                <div className="group flex gap-2 items-center text-base h-8 justify-center">
                                    <i className="ri-graduation-cap-line text-gray-200"></i>
                                    <span className="hover:text-blue-500 text-gray-200">{about && about[0] && about[0].education}</span>
                                </div>
                            </div>
                            <div className="flex gap-3 -mb-5 py-4">
                                <Link to={about && about[0] && about[0].whatsapp} className="group flex gap-2 items-center text-base h-8 justify-center">
                                    <i className="ri-whatsapp-line text-gray-200"></i>
                                    <span className="hover:text-blue-500 text-gray-200">WhatsApp</span>
                                </Link>
                            </div>
                            <div className="flex gap-3 -mb-5 py-4">
                                <Link to={`https://portofolio.jii4you.my.id/storage/${about && about[0] && about[0].document}`} download className="group flex gap-2 items-center text-base h-8 justify-center">
                                    <i className="ri-file-text-line text-gray-200"></i>
                                    <span className="hover:text-blue-500 text-gray-200">Download CV</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-2 relative group overflow-hidden lg:px-6 px-4 rounded-xl border border-gray-700 dark:border-gray-800 dark:bg-gray-900">
                        <div aria-hidden="true" className="inset-0 absolute aspect-video border border-gray-700 -translate-y-1/2 group-hover:-translate-y-1/4 duration-300 bg-gradient-to-b from-blue-900 to-slate-300 dark:from-white dark:to-white blur-2xl opacity-10 dark:opacity-5 dark:group-hover:opacity-10"></div>
                        <div className="relative">
                            <div className="border-b border-gray-700 dark:border-gray-700 mb-4">
                                <ul className="flex flex-wrap -mb-px lg:justify-start justify-between" id="myTab" role="tablist">
                                    <li className="mr-2" role="presentation">
                                    <button
                                        className={`inline-block text-gray-200 hover:text-gray-600 hover:border-gray-300 rounded-t-lg py-5 px-4 text-base font-medium text-center border-transparent border-b-2 dark:text-gray-400 dark:hover:text-gray-300 ${activeTab === 'profile' && 'active'}`}
                                        id="profile-tab"
                                        onClick={() => handleTabClick('profile')}
                                        role="tab"
                                        aria-controls="profile"
                                        aria-selected={activeTab === 'profile'}
                                    >
                                        Project
                                    </button>
                                    </li>
                                    <li className="mr-2" role="presentation">
                                    <button
                                        className={`inline-block text-gray-200 hover:text-gray-600 hover:border-gray-300 rounded-t-lg py-5 px-4 text-base font-medium text-center border-transparent border-b-2 dark:text-gray-400 dark:hover:text-gray-300 ${activeTab === 'dashboard' && 'active'}`}
                                        id="dashboard-tab"
                                        onClick={() => handleTabClick('dashboard')}
                                        role="tab"
                                        aria-controls="dashboard"
                                        aria-selected={activeTab === 'dashboard'}
                                    >
                                        Resume
                                    </button>
                                    </li>
                                    <li className="mr-2" role="presentation">
                                    <button
                                        className={`inline-block text-gray-200 hover:text-gray-600 hover:border-gray-300 rounded-t-lg py-5 px-4 text-base font-medium text-center border-transparent border-b-2 dark:text-gray-400 dark:hover:text-gray-300 ${activeTab === 'settings' && 'active'}`}
                                        id="settings-tab"
                                        onClick={() => handleTabClick('settings')}
                                        role="tab"
                                        aria-controls="settings"
                                        aria-selected={activeTab === 'settings'}
                                    >
                                        Contact
                                    </button>
                                    </li>
                                </ul>
                            </div>
                            <div id="myTabContent" className="mb-4">
                                <div className={`grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 lg:p-4 p-1 rounded-lg dark:bg-gray-800 ${activeTab !== 'profile' && 'hidden'}`} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                {project.map((pdata) => {
                                    return (
                                        <Link key={pdata.id} to={pdata.link} className="lg:max-w-sm w-full border border-gray-700 px-4 pt-4 pb-2 rounded-xl shadow-lg transform hover:scale-105 transition duration-300">
                                            <div className="relative h-44">
                                                <img className="w-full h-full rounded-xl" src={`https://portofolio.jii4you.my.id/storage/${pdata.image}`} alt="Colors"/>
                                            </div>
                                            <div className="flex justify-start gap-2 my-3">
                                                {pdata.category.map((category) => (
                                                    <p key={category.id} className="text-xs text-gray-200 border border-gray-700 px-2 py-1 rounded">
                                                        {category.name}
                                                    </p>
                                                ))}
                                            </div>
                                            <h1 className="text-gray-200 text-lg font-semibold cursor-pointer mb-2 hover:underline">
                                                {pdata.title}
                                            </h1>
                                        </Link>
                                    );
                                })}
                                </div>
                            </div>
                            <div className={`lg:p-4 p-1 rounded-lg dark:bg-gray-800 mb-5 ${activeTab !== 'dashboard' && 'hidden'}`} id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                                <div className="flex gap-2 lg:px-4 px-3 py-2 border rounded-b-none border-gray-700 rounded-md lg:mb-0">
                                    <i className="ri-shake-hands-line text-lg text-gray-200"></i>
                                    <h1 className="text-lg text-gray-200">Experience</h1>
                                </div>
                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:px-4 px-3 py-2 border rounded-t-none border-gray-700 rounded-md">
                                    {experience.length > 0 ? (
                                        experience.map((pdata) => (
                                            <div key={pdata.id} className="text-justify">
                                                <h1 className="text-base text-gray-200">{pdata.title}</h1>
                                                <p className="text-gray-500 dark:text-gray-400 text-sm my-3">
                                                    {pdata.description}
                                                </p>
                                                <p className="text-xs text-gray-400 font-light">{pdata.from_year} - {pdata.until_year}</p>
                                            </div>
                                        ))
                                    ) : (
                                        <p className='text-gray-200'>No Experience !</p>
                                    )}
                                </div>
                                <div className="border border-gray-700 my-5"></div>
                                <div className="flex gap-2 lg:px-4 px-3 py-2 border rounded-b-none border-gray-700 rounded-md lg:mb-0">
                                    <i className="ri-graduation-cap-line text-lg text-gray-200"></i>
                                    <h1 className="text-lg text-gray-200">Education</h1>
                                </div>
                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:px-4 px-3 py-2 border rounded-t-none border-gray-700 rounded-md">
                                    {education.length > 0 ? (
                                        education.map((pdata) => (
                                            <div key={pdata.id} className="text-justify">
                                                <h1 className="text-base text-gray-200">{pdata.title}</h1>
                                                <p className="text-gray-500 dark:text-gray-400 text-sm my-3">
                                                    {pdata.description}
                                                </p>
                                                <p className="text-xs text-gray-400 font-light">{pdata.from_year} - {pdata.until_year}</p>
                                            </div>
                                        ))
                                    ) : (
                                        <p className='text-gray-200'>No Experience !</p>
                                    )}
                                </div>
                            </div>
                            <div className={`lg:p-4 p-1 rounded-lg dark:bg-gray-800 mb-5 ${activeTab !== 'settings' && 'hidden'}`} id="settings" role="tabpanel" aria-labelledby="settings-tab">
                                <div className="flex items-center justify-center gap-2 lg:px-4 px-3 py-4 border rounded-b-none border-gray-700 rounded-md lg:mb-0">
                                    <h1 className="text-3xl text-gray-200 font-semibold">Contact Me</h1>
                                </div>
                                <div className="lg:px-4 px-3 py-2 border rounded-t-none border-gray-700 rounded-md">
                                    <form action="" method="post" className="mb-3">
                                        <div className="md:flex items-center mt-12">
                                            <div className="w-full md:w-1/2 flex flex-col">
                                                <label className="font-semibold leading-none text-gray-300">Name</label>
                                                <input type="text" className="leading-none text-gray-50 p-3 focus:outline-none focus:border-blue-700 mt-4 border-0 bg-gray-700 rounded" />
                                            </div>
                                            <div className="w-full md:w-1/2 flex flex-col md:ml-6 md:mt-0 mt-4">
                                                <label className="font-semibold leading-none text-gray-300">Email</label>
                                                <input type="email" className="leading-none text-gray-50 p-3 focus:outline-none focus:border-blue-700 mt-4 border-0 bg-gray-700 rounded"/>
                                            </div>
                                        </div>
                                        <div className="md:flex items-center mt-8">
                                            <div className="w-full flex flex-col">
                                                <label className="font-semibold leading-none text-gray-300">Subject</label>
                                                <input type="text" className="leading-none text-gray-50 p-3 focus:outline-none focus:border-blue-700 mt-4 border-0 bg-gray-700 rounded"/>
                                            </div>
                                            
                                        </div>
                                        <div>
                                            <div className="w-full flex flex-col mt-8">
                                                <label className="font-semibold leading-none text-gray-300">Message</label>
                                                <textarea type="text" className="h-40 text-base leading-none text-gray-50 p-3 focus:outline-none focus:border-blue-700 mt-4 bg-gray-700 border-0 rounded"></textarea>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-center w-full">
                                            <button className="mt-9 font-semibold leading-none text-white py-4 px-10 bg-gray-700 rounded hover:bg-gray-600 focus:ring-2 focus:ring-offset-2 focus:ring-blue-700 focus:outline-none">
                                                Send message
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="lg:flex block items-center lg:justify-around my-5 border border-gray-700 lg:px-4 px-1 lg:py-2 py-3 rounded-md">
                    <div className="flex justify-center">
                        <p className="text-base text-gray-200">&copy; Developed by</p>
                        <Link to="/" className="text-base text-blue-700 italic uppercase ml-2">Aji Nur Iman</Link>
                    </div>
                    <p className="text-base text-gray-200 font-medium text-center lg:my-0 my-3">V 1.1</p>
                    <div className="flex justify-center gap-4">
                        <Link to={footer && footer[0] && footer[0].twitter}>
                            <i className="ri-twitter-x-line text-base text-gray-200"></i>
                            <span className="text-base text-gray-200 ml-1 hover:text-blue-500">Twitter</span>
                        </Link>
                        <Link to={footer && footer[0] && footer[0].facebook}>
                            <i className="ri-facebook-circle-line text-base text-gray-200"></i>
                            <span className="text-base text-gray-200 ml-1 hover:text-blue-500">Facebook</span>
                        </Link>
                        <Link to={footer && footer[0] && footer[0].instagram}>
                            <i className="ri-instagram-line text-base text-gray-200"></i>
                            <span className="text-base text-gray-200 ml-1 hover:text-blue-500">Instagram</span>
                        </Link>
                    </div>
                </footer>
            </div>
        </>
    )
}

export default HomePages