import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePages from "./pages/home/HomePages";
import './App.css';

function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route index element={<HomePages />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
